/* eslint-disable no-multi-spaces, key-spacing */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { func, array, shape, string, object } from 'prop-types';
import FloatingLabel from '@getgo/chameleon-react/dist/FloatingLabel';

import { anyCharRegex } from '../../../Utils/formats';
import QuestionType from '../../../Api/Webinar/Survey/Model/questionType';
import MultipleChoice from './MultipleChoice/MultipleChoice';
import RatingScale from './RatingScale/RatingScale';
import styles from './QuestionSelection.scss';

import mcSVG from '../../../Components/Common/Assets/checkBoxGrey.svg';
import scSVG from '../../../Components/Common/Assets/singleChoiceGray.svg';

const DEAFULT_QUESTION = QuestionType.MULTIPLE_CHOICE;

const QuestionSelection = (props) => {
  const { t } = useTranslation();

  const { choices, question, updateSurveyQuestion, invalidFields } = props;

  const hasNoTitle = invalidFields && invalidFields.title;
  const hasError = invalidFields?.xssErrors?.title;

  const updateQuestionText = (questionText) => {
    const updatedQuestion = {
      ...question,
      questionText,
      choices: choices.map((c) => c.answerKey)
    };
    updateSurveyQuestion(updatedQuestion, updatedQuestion.questionKey);
  };

  return (
    <div className={styles.questionSelection}>
      <div className={styles.floatingLabelWrapper}>
        <FloatingLabel
          charLimitText={t('survey.charLimit')}
          containerClassNames={classnames(styles.floatingLabel, { [styles.error]: hasNoTitle || hasError })}
          floatLabel={false}
          form
          id="tc_question_text"
          maxLength={512}
          onChange={updateQuestionText}
          pattern={anyCharRegex}
          placeholder={t('survey.questions.placeholder')}
          showAnimatedBar={!(hasNoTitle || hasError)}
          showErrorMessageOnlyOnBlur={false}
          value={question.questionText}
        />
        {hasNoTitle
          && <span className={styles.required}>
            {t('survey.questions.requiredField')}
          </span>
        }
        {hasError
          && <span className={styles.errorText}>
            {t('survey.notification.invalidTextFail')}
          </span>
        }
      </div>

      {{
        [QuestionType.MULTIPLE_CHOICE]: <MultipleChoice {...props} icon={mcSVG} />,
        [QuestionType.SINGLE_CHOICE]: <MultipleChoice {...props} icon={scSVG} />,
        [QuestionType.RATING]: <RatingScale    {...props} />,
        [QuestionType.SHORT_ANSWER]: null
      }[props.question.type || DEAFULT_QUESTION]}
    </div>
  );
};

QuestionSelection.propTypes = {
  choices: array.isRequired,
  question: shape({
    questionText: string
  }).isRequired,
  updateSurveyQuestion: func,
  invalidFields: object
};

export default QuestionSelection;
