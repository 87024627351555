/* eslint-disable camelcase, no-multi-spaces */
import { createSelector } from 'reselect';
import { get } from '../../Utils/objectHelpers';
import { xssRegex } from '../../Utils/formats';

const LOW_RATING  = 'low_rating';
const HIGH_RATING = 'high_rating';
const LOW_LABEL   = 'low_label';
const MID_LABEL   = 'mid_label';
const HIGH_LABEL  = 'high_label';

export const ratingFields = {
  LOW_RATING,
  HIGH_RATING,
  LOW_LABEL,
  HIGH_LABEL,
  MID_LABEL
};

const createChoiceFromProp = (prop, { answerKey, answerText }) => ({
  [prop]: {
    value: answerText.slice(prop.length + 1),
    answerKey
  }
});

/**
 * Returns map of new key/value pairs
 * from answerText property in choice
 * (eg: [{ answerText: 'low_rating:0', answerKey: '123' }] -> { low_rating: { answerKey: '123', value: 0 } })
 * @param {array} choices
 * @returns {object}
 */
export const extractKeyValuePairs = (choices = []) => {
  let accumulator = {};
  let value = {};

  const result = choices.reduce((acc, choice) => {
    const { answerKey, answerText } = choice;

    if (answerText.slice(0, LOW_RATING.length) === LOW_RATING && !acc[LOW_RATING]) {
      value = createChoiceFromProp(LOW_RATING, choice);
      accumulator = { ...acc, ...value };
      return accumulator;
    }

    if (answerText.slice(0, HIGH_RATING.length) === HIGH_RATING && !acc[HIGH_RATING]) {
      value = createChoiceFromProp(HIGH_RATING, choice);
      accumulator = { ...acc, ...value };
      return accumulator;
    }

    if (answerText.slice(0, LOW_LABEL.length) === LOW_LABEL && !acc[LOW_LABEL]) {
      value = createChoiceFromProp(LOW_LABEL, choice);
      accumulator = { ...acc, ...value };
      return accumulator;
    }

    if (answerText.slice(0, MID_LABEL.length) === MID_LABEL && !acc[MID_LABEL]) {
      value = createChoiceFromProp(MID_LABEL, choice);
      accumulator = { ...acc, ...value };
      return accumulator;
    }

    if (answerText.slice(0, HIGH_LABEL.length) === HIGH_LABEL && !acc[HIGH_LABEL]) {
      value = createChoiceFromProp(HIGH_LABEL, choice);
      accumulator = { ...acc, ...value };
      return accumulator;
    }

    // NOTE: Set default key/value pairs for choices that don't exist yet. (eg: a new rating)
    if (!value[LOW_RATING] && !acc[LOW_RATING]) {
      value = createChoiceFromProp(LOW_RATING, { answerKey, answerText: `${LOW_RATING}:1` });
      accumulator = { ...acc, ...value };
      return accumulator;
    }

    if (!value[HIGH_RATING] && !acc[HIGH_RATING]) {
      value = createChoiceFromProp(HIGH_RATING, { answerKey, answerText: `${HIGH_RATING}:5` });
      accumulator = { ...acc, ...value };
      return accumulator;
    }

    if (!value[LOW_LABEL] && !acc[LOW_LABEL]) {
      value = createChoiceFromProp(LOW_LABEL, { answerKey, answerText: `${LOW_LABEL}:` });
      accumulator = { ...acc, ...value };
      return accumulator;
    }

    if (!value[MID_LABEL] && !acc[MID_LABEL]) {
      value = createChoiceFromProp(MID_LABEL, { answerKey, answerText: `${MID_LABEL}:` });
      accumulator = { ...acc, ...value };
      return accumulator;
    }

    if (!value[HIGH_LABEL] && !acc[HIGH_LABEL]) {
      value = createChoiceFromProp(HIGH_LABEL, { answerKey, answerText: `${HIGH_LABEL}:` });
      accumulator = { ...acc, ...value };
      return accumulator;
    }

    return accumulator;
  }, accumulator);

  return result;
};

const hasSurveySettingsOn = createSelector(
  [
    (state) => get(state, (s) => s.survey.settings)
  ],
  (settings) => Object.keys(settings || {}).some((setting) => settings[setting])
);

export const selectActiveQuestion = createSelector(
  [
    (state) => get(state, (s) => s.survey.activeQuestion, {})
  ],
  (activeQuestion) => activeQuestion
);

export const selectSurveyChoicesCopy = createSelector(
  [
    (state) => get(state, (s) => s._entities.surveyChoices.copy.byId, {})
  ],
  (surveyChoices) => surveyChoices
);

export const selectSurveyQuestionsCopy = createSelector(
  [
    (state) => get(state, (s) => s._entities.surveyQuestions.copy.byId, {}),
    (state) => get(state, (s) => s._entities.surveyQuestions.copy.ids, [])
  ],
  (surveyQuestions, ids) => ids.map((id) => surveyQuestions[id])
);

export const selectActiveChoices = createSelector(
  [
    (state) => get(state, (s) => s.survey.activeQuestion.choices, []),
    selectSurveyChoicesCopy,
    (state) => get(state, (s) => s.survey.activeChoices, {})
  ],
  (questionChoices, surveyChoices, activeChoices) => {
    const allChoices = { ...surveyChoices, ...activeChoices };
    const choices = questionChoices.reduce((acc, answerKey) => {
      if (allChoices[answerKey]) {
        acc.push(allChoices[answerKey]);
        return acc;
      }
      return acc;
    }, []);
    return choices;
  }
);

export const selectDoesQuestionExist = createSelector(
  [
    (state) => get(state, (s) => s._entities.surveyQuestions.copy.byId, {}),
    (state) => get(state, (s) => s.survey.activeQuestionKey)
  ],
  (surveyQuestions, questionKey) => {
    if (surveyQuestions[questionKey]) {
      return true;
    }
    return false;
  }
);

export const selectIsSaveDisabled = createSelector(
  [
    (state) => get(state, (s) => s.survey.title),
    selectSurveyQuestionsCopy,
    hasSurveySettingsOn
  ],
  (title, questions, hasSettingsOn) => (!title || !questions.length || !hasSettingsOn || xssRegex.test(title))
);

export const selectIsSaveDisabledForSurvey = createSelector(
  [
    (state) => get(state, (s) => s.survey.title),
    selectSurveyQuestionsCopy
  ],
  (title, questions) => (!title || !questions.length)
);

const sanitize = (choicesById, questions = []) => {
  const filterAnswerText = (acc, answerKey) => {
    if (choicesById[answerKey]) {
      const { answerText } = choicesById[answerKey];
      acc.push({ answerText });
    }
    return acc;
  };

  return questions.map(({ questionKey, choices, ...rest }) => ({
    ...rest,
    choices: choices.reduce(filterAnswerText, [])
  }));
};

export const selectSurvey = createSelector(
  [
    (state) => get(state, (s) => s.survey.settings),
    (state) => get(state, (s) => s.survey.title),
    selectSurveyQuestionsCopy,
    selectSurveyChoicesCopy
  ],
  (settings, title, questions, choicesById) => ({
    questions: sanitize(choicesById,  questions),
    settings,
    title
  })
);
