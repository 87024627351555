import React from 'react';
import { bool, func, string } from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FloatingLabel } from '@getgo/chameleon-react';

import { anyCharRegex } from '../../../Utils/formats';
import styles from './SurveyTitle.scss';

const SurveyTitle = ({ title, updateSurveyTitle, hasXssError }) => {
  const { t } = useTranslation();

  const saveTitle = (text) => updateSurveyTitle(text);

  return (
    <>
      <FloatingLabel
        charLimitText={t('survey.charLimit')}
        errorMessage={t('survey.questions.requiredField')}
        containerClassNames={classnames(styles.floatingLabel, { [styles.error]: hasXssError })}
        floatLabel={false}
        form
        id="tc_survey_title"
        inputClassName={styles.surveyTitle}
        maxLength={128}
        onChange={saveTitle}
        pattern={anyCharRegex}
        placeholder={t('survey.placeholder')}
        showAnimatedBar={!hasXssError}
        value={title}
      />
      {hasXssError
        && <span className={styles.errorText}>
          {t('survey.notification.invalidTextFail')}
        </span>
      }
    </>
  );
};

SurveyTitle.propTypes = {
  title: string,
  updateSurveyTitle: func.isRequired,
  hasXssError: bool
};

export default React.memo(
  SurveyTitle,
  (prevProps, nextProps) => prevProps.title === nextProps.title
);
